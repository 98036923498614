import React from 'react';

/**
 * Creates horizontal line to break up sections of content.
 * User can specify color of the line.
 */

const ColoredLine = ({ color, width, height, margin }) => (
  <div
    style={{
      background: color,
      width: width,
      height: height,
      margin: margin,
    }}
  ></div>
);

ColoredLine.defaultProps = {
  color: `var(--darkestEarth)`,
  width: `100%`,
  height: '0.2rem',
  margin: '0 auto 1.5rem auto',
};

export default ColoredLine;
