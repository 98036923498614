import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import PostCard from './PostCard';

const StyledPostGrid = ({ heading, posts, className, children }) => {
  return (
    <section className={className}>
      <Title
        title={heading}
        padding={'2rem 0 1rem 0'}
        fontSize={'1.5rem'}
      ></Title>
      <div className="postGrid">
        {posts.map(({ node }, idx) => {
          return <PostCard key={idx} post={node}></PostCard>;
        })}
      </div>
      {children}
    </section>
  );
};

export const PostGrid = styled(StyledPostGrid)`
  background: var(--mainEarth);
  padding-bottom: 2.5rem;
  min-height: 20rem;

  .postGrid {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 95%;
    margin: 0rem auto;
    padding-bottom: 2rem;
  }

  @media (min-width: 576px) {
    .postGrid {
      grid-template-columns: 1fr 1fr;
      max-width: 85%;
    }
  }

  @media (min-width: 992px) {
    .postGrid {
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 80%;
    }
  }
`;

export const RecentPostGrid = styled(PostGrid)`
  padding-top: 2rem;
`;
