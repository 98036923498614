import React from 'react';
import styled from 'styled-components';
import logo from '../images/logo.svg';

const Logo = ({ className }) => {
  return (
    <img
      className={className}
      src={logo}
      alt="thecodesmith anvil and hammer logo"
    />
  );
};

export default styled(Logo)`
  width: 175px;
  height: 145px;
  display: block;
  margin-top: 1rem;

  @media (min-width: 640px) {
    width: 225px;
    height: 185px;
    margin-top: 2rem;
  }
`;
