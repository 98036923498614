import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import SEO from '../components/seo';
import Logo from '../components/Logo';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import SimpleHero from '../components/SimpleHero';
import TopicGrids from '../components/Home/TopicGrids';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <SimpleHero>
        <Logo />
        <Banner
          title="Crafting Code"
          info="Articles on popular programming topics"
        >
          <AniLink fade to="/explore">
            <button className="btn-white">Explore Topics</button>
          </AniLink>
        </Banner>
      </SimpleHero>
      <TopicGrids></TopicGrids>
    </Layout>
  );
};

export default IndexPage;
