import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ColoredLine from '../components/utils/ColoredLine';

const PostCard = ({ post, className }) => {
  const { title, topic, slug, summary } = post.frontmatter;
  return (
    <AniLink fade to={`/${topic}/${slug}`} className={className}>
      <h3>{title}</h3>
      {/* Summary only shown on topic specific pages */}
      {summary ? (
        <>
          <ColoredLine width={'35%'} margin={'0.6rem auto'} />
          <p>{summary}</p>
        </>
      ) : null}
    </AniLink>
  );
};

export default styled(PostCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  padding: 1.5rem 0.5rem;
  margin: 0.75rem 0.75rem;
  background: var(--cardEarth);
  color: white;
  text-align: center;
  transition: var(--mainTransition);
  &:hover {
    transform: translateY(-3px);
    h3 {
      color: var(--primaryColor);
    }
  }

  h3 {
    width: 90%;
    font-size: 1.05rem;
    transition: var(--mainTransition);
  }

  p {
    font-weight: lighter;
    font-size: 1rem;
    letter-spacing: 0.125rem;
    width: 95%;
  }

  h3,
  p {
    margin-bottom: 0;
  }

  @media (min-width: 576px) {
    min-height: 8rem;
  }

  @media (min-width: 768px) {
    h3,
    p {
      font-weight: bold;
    }

    h3 {
      font-size: 1.2rem;
    }
  }
`;
