import React from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import { graphql, useStaticQuery } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { PostGrid } from '../PostGrid';
import topics from '../../constants/topics';
import ColoredLine from '../utils/ColoredLine';

const TopicGrids = ({ className }) => {
  const response = useStaticQuery(getTopicPreviews);
  return (
    <section className={className}>
      {topics.map((topic, idx) => {
        const posts = response[topic] ? response[topic].edges : [];
        return (
          <div key={idx}>
            <PostGrid heading={topic} posts={posts}>
              <AniLink className="allPostsIcon" to={`/${topic}`}>
                <FaPlus></FaPlus>
              </AniLink>
            </PostGrid>
            {idx < topics.length - 1 ? (
              <ColoredLine width={'80%'} height={'0.25rem'} />
            ) : null}
          </div>
        );
      })}
    </section>
  );
};

const getTopicPreviews = graphql`
  query getTopicPreviews {
    html: allMdx(
      filter: { frontmatter: { tags: { eq: "html" } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            topic
          }
        }
      }
    }
    css: allMdx(
      filter: { frontmatter: { tags: { eq: "css" } } }
      limit: 6
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            topic
          }
        }
      }
    }
  }
`;

export default styled(TopicGrids)`
  color: white;
  min-height: 20rem;
  background: var(--mainEarth);
  padding: 1rem 0;
  text-align: center;

  .allPostsIcon {
    display: inline-block;
    padding: 0.8rem 0.9rem 0.6rem 0.9rem;
    color: white;
    background-color: var(--darkestEarth);
    border-radius: 0.75rem;
    transition: var(--mainTransition);
    &:hover {
      transform: translateY(-3px);
      color: var(--primaryColor);
    }
  }
`;
